import { createGlobalStyle } from 'styled-components';

import ClashDisplayWoff from '../fonts/ClashDisplay-Variable.woff';
import ClashDisplayWoff2 from '../fonts/ClashDisplay-Variable.woff2';
import ClashDisplayTtf from '../fonts/ClashDisplay-Variable.ttf';

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: "ClashDisplay-Variable";
  src: url(${ClashDisplayWoff2}) format("woff2"),
    url(${ClashDisplayWoff}) format("woff"),
    url(${ClashDisplayTtf}) format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

:root {
  font-size: 62.5%;
  cursor: none;

}

*,*::before,*::after{
    font-family: "ClashDisplay-Variable";
    box-sizing: border-box;
    padding: 0;
    margin: 0;  
}

html{
  scroll-behavior:smooth;
::-webkit-scrollbar {
  display: none;}
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  cursor: none;

  :focus-within {
  scroll-behavior: smooth;
}
}
    body,
    html,
    a {
        font-family: "ClashDisplay-Variable";
            }
    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #090A09;
        overflow-x: hidden;


        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin:0;
        padding:0;
    }
    a {
        text-decoration: none;
        outline: none;
    }
    button{
        border:none;
        outline:none;
        &:focus{
            outline:none;
        }
    }
    *:focus {
        outline: none;
    }
    img,svg{
        width:100%;
        height:auto;
    }
`;
