import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.footer};
  color: ${({ theme }) => theme.colors.fontLight};

  display: grid;
  height: auto;
  min-height: 8vh;
  margin-top: auto;
`;

export const FooterWrap = styled.div`
  display: grid;
  margin-left: 5rem;
  margin-right: 5rem;
  gap: 1rem;
  grid-auto-columns: 1fr;
  grid-template-areas: 'imprint imprint imprint imprint . desktop desktop desktop desktop desktop desktop desktop';

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    grid-template-areas:
      '. text text text text text text text text text text .'
      '. links links links links links links links links links links .'
      '. icons icons icons icons icons icons icons icons icons icons .'
      '. imprint imprint imprint imprint imprint imprint imprint imprint imprint imprint  .';

    gap: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const DesktopWrap = styled.div`
  grid-area: desktop;
  display: flex;
  gap: 4rem;
  justify-content: right;
  justify-self: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: none;
  }
`;

export const DesktopFooterLinkItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.gap.big};
`;

export const FooterLinkDesktop = styled(Link)`
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.size.normal};
  font-variation-settings: 'wght' 400;
  color: ${({ theme }) => theme.colors.fontLight};
  text-decoration: none;
  transition: ${({ theme }) => theme.transition.link};

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
  }
`;

export const DesktopIconWrap = styled.div`
  display: flex;
  justify-content: right;
  align-items: right;
  width: ${({ theme }) => theme.size.big};
  height: ${({ theme }) => theme.size.big};
  margin-left: 2rem;
  transition: ${({ theme }) => theme.transition.link};

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
  }
`;

export const FooterTextWrap = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    grid-area: text;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    text-align: center;
    font-size: ${({ theme }) => theme.size.super};
    line-height: 4rem;
    margin-bottom: 7rem;
    margin-top: 5rem;

    p {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-variation-settings: 'wght' 600;
      letter-spacing: ${({ theme }) => theme.spacing.small};
    }

    span {
      font-family: ${({ theme }) => theme.fonts.primary};
      font-variation-settings: 'wght' 300;
      letter-spacing: ${({ theme }) => theme.spacing.small};
    }

    a {
      font-family: ${({ theme }) => theme.fonts.secondary};
      font-size: ${({ theme }) => theme.size.normal};
      font-variation-settings: 'wght' 500;
      text-decoration: underline;
      text-align: center;
      //text-transform: uppercase;
      letter-spacing: ${({ theme }) => theme.spacing.small};
      color: ${({ theme }) => theme.colors.yellow};
      transition: ${({ theme }) => theme.transition.link};

      &:hover {
        //color: ${({ theme }) => theme.colors.hoverLight};
      }
    }
  }
`;

export const FooterLinkItems = styled.div`
  grid-area: links;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.gap.large};
    margin-bottom: 9rem;
  }
`;

export const FooterLinkMobile = styled(Link)`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: flex;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.size.xxl};
    font-variation-settings: 'wght' 500;
    color: ${({ theme }) => theme.colors.fontLight};
    text-transform: uppercase;
    text-decoration: none;
    transition: ${({ theme }) => theme.transition.link};
    margin: 0.5rem;

    &:hover {
      //color: ${({ theme }) => theme.colors.hoverLight};
    }
  }
`;

export const SocialIconsWrap = styled.div`
  display: none;
  grid-area: icons;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7.5rem;
  }
`;

export const SocialIconsHeadline = styled.h2`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: flex;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.size.normal};
    font-variation-settings: 'wght' 500;
    letter-spacing: 0.1rem;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.gap.big};

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    gap: 4rem;
  }
`;

export const SocialIconLink = styled.a`
  color: ${({ theme }) => theme.colors.fontLight};
  width: ${({ theme }) => theme.size.xxl};
  display: flex;
  transition: ${({ theme }) => theme.transition.link};

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
  }

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    width: ${({ theme }) => theme.size.mega};
    height: ${({ theme }) => theme.size.mega};
    justify-content: center;
    align-items: center;
  }
`;

export const TopScrollWrap = styled.div`
  grid-area: totop;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    margin-bottom: 5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TopScrollIcon = styled.button`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.footer};

    transition: ${({ theme }) => theme.transition.link};

    img {
      width: 8rem;
    }

    &:hover {
      //color: ${({ theme }) => theme.colors.hoverLight};
    }
  }
`;

export const WebsiteRights = styled.div`
  grid-area: imprint;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: ${({ theme }) => theme.size.normal};
  font-variation-settings: 'wght' 400;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    justify-content: center;
    font-size: ${({ theme }) => theme.size.small};
  }
`;

export const FooterLinkImprint = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.fontLight};
  font-size: ${({ theme }) => theme.size.normal};
  letter-spacing: ${({ theme }) => theme.spacing.small};
  font-variation-settings: 'wght' 430;
  margin-left: 1rem;

  transition: ${({ theme }) => theme.transition.link};

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
  }

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    justify-content: center;
    margin-left: 0rem;
    font-size: ${({ theme }) => theme.size.small};
  }
`;
