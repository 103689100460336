export const lightTheme = {
    colors: {
        fontLight: "#f4f4f4",
        fontDark: "#090A09",
        bgLight: "#f4f4f4",
        bgDark: "#090A09",
        navbar: "#f4f4f4",
        footer: "#090A09",
        hoverLight: "#FCFFCA",
        hoverDark: "#444444",
        blue: "#B0C3D3",
        yellow: "#FCFFCA",
        outline: "#292929"
    },

    fonts: {
        primary: "ClashDisplay-Variable",
        secondary: "ClashDisplay-Variable"
    },

    size: {
        xs: "1.2rem",
        small: "1.4rem",
        normal: "1.6rem",
        large: "1.8rem",
        xl: "2.0rem",
        xxl: "2.4rem",
        big: "3.0rem",
        super: "3.6rem",
        mega: "4rem"
    },

    spacing: {
        xs: "0.05rem",
        small: "0.1rem",
        normal: "0.2rem",
        big: "0.3rem"
    },

    gap: {
        small: "0.5rem",
        normal: "1rem",
        large: "1.5rem",
        big: "2rem"
    },

    transition: {
        slow: "0.7s all ease",
        normal: "0.5s all ease",
        fast: "0.3s all ease",
        sidebar: "0.5s all ease",
        link: "0.2s all ease"
    },

    responsive: {
    mobile: "39.9375em",
    tablet: "820px",
    desktop: "64em"
    },  


}