import React, { useEffect, useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Spiral as Hamburger } from 'hamburger-react';
import { FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { motion } from 'framer-motion';
import {
  Headers,
  NavLogo,
  MobileIcon,
  Nav,
  NavLinks,
  MobileMenu,
  SidebarWrapper,
  SidebarMenuWrapper,
  SidebarMenu,
  SidebarRoute,
  SidebarSocialIconsWrapper,
  SocialIconsHeadline,
  SidebarSocialIcons,
  SidebarSocialIconLink,
  CopyrightWrapper,
  SidebarRights,
  FooterLinkImprint,
} from './NavbarElements';
import MagneticElement from '../magnetic/MagneticElement';

const Navbar = () => {
  const [scrollnav, setscrollnav] = useState(false);

  const changeNav = () => {
    if (window.scrollY > 10) {
      setscrollnav(true);
    } else {
      setscrollnav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleTop = () => {
    scroll.scrollToTop();
  };

  const [open, setOpen] = useState(false);
  const close = () => setOpen(!open);

  const reveal = {
    hidden: { y: -20, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1,
        duration: 0.3,
        ease: [0.6, -0.05, 0.01, 0.99],
        staggerChildren: 0.5,
      },
    },
  };

  const headeritems = {
    hidden: { y: -20, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const menu = {
    initial: { y: 10, opacity: 0 },
    whileInView: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.25,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
    viewport: { once: true },
    transition: { duration: 0.3 },
  };

  return (
    <>
      <Headers
        as={motion.div}
        variants={reveal}
        initial="hidden"
        animate="show"
        scrollnav={scrollnav}
      >
        <NavLogo to="/" onClick={toggleTop} scrollnav={scrollnav} open={open}>
          leo doerfler
        </NavLogo>
        <Nav as={motion.nav} variants={headeritems}>
          <MagneticElement
            style={{
              width: '50px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NavLinks scrollnav={scrollnav} to="/film">
              projects
            </NavLinks>
          </MagneticElement>
          <MagneticElement
            style={{
              width: '50px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NavLinks scrollnav={scrollnav} to="/about">
              about
            </NavLinks>
          </MagneticElement>
        </Nav>
        <MobileIcon>
          <Hamburger
            color={scrollnav || open ? 'black' : 'white'}
            onClick={close}
            toggled={open}
            toggle={setOpen}
            rounded={48}
            size={30}
            distance="sm"
            aria-label="hamburger menu"
          />
        </MobileIcon>
        <MobileMenu open={open} onClick={close}>
          <SidebarWrapper>
            <SidebarMenuWrapper>
              <SidebarMenu>
                <SidebarRoute to="/film">projects</SidebarRoute>
                <SidebarRoute to="/about">about</SidebarRoute>
              </SidebarMenu>
            </SidebarMenuWrapper>
            <SidebarSocialIconsWrapper>
              <SidebarSocialIcons>
                <SidebarSocialIconLink
                  href="//www.instagram.com/leo.doerfler/"
                  target="_blank"
                  aria-label="instagram"
                >
                  <FaInstagram />
                </SidebarSocialIconLink>
                <SidebarSocialIconLink
                  href="//www.youtube.com/leodoerfler/"
                  target="_blank"
                  aria-label="youtube"
                >
                  <FaYoutube />
                </SidebarSocialIconLink>
                <SidebarSocialIconLink
                  href="//www.linkedin.com/in/leodoerfler"
                  target="_blank"
                  aria-label="linkedin"
                >
                  <FaLinkedin />
                </SidebarSocialIconLink>
              </SidebarSocialIcons>
            </SidebarSocialIconsWrapper>
            <CopyrightWrapper>
              <SidebarRights>
                copyright © {new Date().getFullYear()} /{' '}
                <FooterLinkImprint to="/imprint">imprint</FooterLinkImprint>
              </SidebarRights>
            </CopyrightWrapper>
          </SidebarWrapper>
        </MobileMenu>
      </Headers>
    </>
  );
};

export default Navbar;
