import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import React from 'react';
import { GlobalStyle } from './theme/global';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from './theme/theme';
import { AnimatePresence } from 'framer-motion';

import ScrollToTop from './components/ScrollToTop';

import Footer from './components/footer';
import Navbar from './components/navbar';

const HomePage = React.lazy(() => import('./pages/Home'));
const AboutPage = React.lazy(() => import('./pages/About'));
const Imprint = React.lazy(() => import('./pages/Imprint'));
const FilmPage = React.lazy(() => import('./components/filmsection'));
const PhotoPage = React.lazy(() => import('./components/photosection'));
const ProjectSoloSection = React.lazy(() => import('./components/projectsolosection'));
const NotFound = React.lazy(() => import('./pages/NotFoundPage'));

function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Navbar />
      <React.Suspense fallback={<p>Loading</p>}>
        <AnimatePresence exitBeforeEnter>
          <ScrollToTop key="scrollbar" />
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/film" element={<FilmPage />} />
            <Route path="/project/:slug" element={<ProjectSoloSection />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
          <Footer key="footer" />
        </AnimatePresence>
      </React.Suspense>
    </ThemeProvider>
  );
}

export default App;
