import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const Headers = styled.header`
  background: ${({ scrollnav, theme }) => (scrollnav ? theme.colors.bgLight : 'transparent')};
  height: 50px;
  margin-top: -50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.size.normal};
  
  position: sticky;
  top: 0.5rem;
  z-index: 50;
  transition: ${({ theme }) => theme.transition.normal};
  border-radius: ${({ scrollnav }) => (scrollnav ? '10rem' : 0)};
  box-shadow: ${({ scrollnav }) => (scrollnav ? '2px 3px 5px 3px rgba(0,0,0,0.1)' : 'transparent')};
  margin-left: ${({ scrollnav }) => (scrollnav ? '2rem' : 0)};
  margin-right: ${({ scrollnav }) => (scrollnav ? '2rem' : 0)};

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    top: 0.6rem;
    padding: 1rem;
    margin-left: ${({ scrollnav }) => (scrollnav ? '1rem' : 0)};
    margin-right: ${({ scrollnav }) => (scrollnav ? '1rem' : 0)};
    border-radius: ${({ open, scrollnav }) => (open || scrollnav ? '10rem' : 0)};
    transition: ${({ theme }) => theme.transition.normal};
  }
`;

export const NavLogo = styled(LinkR)`
  z-index: 999;
  display: flex;
  color: ${({ open, scrollnav, theme }) =>
    open || scrollnav ? theme.colors.fontDark : theme.colors.fontLight};
  align-items: center;
  margin-left: 4rem;
  text-decoration: none;
  font-size: ${({ theme }) => theme.size.xl};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  letter-spacing: ${({ theme }) => theme.spacing.xs};
  font-variation-settings: 'wght' 600;
  text-transform: uppercase;
  transition: ${({ theme }) => theme.transition.slow};

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    margin-left: 1.5rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: flex;
    position: absolute;
    right: 0;
    margin-right: 1.5rem;
    z-index: 999;
    transition: ${({ theme }) => theme.transition.slow};
  }
`;

export const Nav = styled.nav`
  display: flex;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
  margin-right: 4rem;

  @media screen and (max-width: ${({ theme }) => theme.responsive.tablet}) {
    display: none;
  }
`;

export const NavLinks = styled(LinkR)`
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ scrollnav, theme }) => (scrollnav ? theme.colors.fontDark : theme.colors.fontLight)};
  transition: ${({ theme }) => theme.transition.link};
  letter-spacing: ${({ theme }) => theme.spacing.small};
  font-variation-settings: 'wght' 430;

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
    //font-variation-settings: 'wght' 500;
  }
`;

export const MobileMenu = styled.div`
  z-index: 998;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.bgLight};
  opacity: ${(props) => (props.open ? '1' : 0)};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  transition: ${({ theme }) => theme.transition.sidebar};
`;

export const SidebarWrapper = styled.div`
  width: 100%;
  margin-top: 8vh;
  display: grid;
  gap: 0.5rem;
  grid-auto-columns: 1fr;
  grid-template-areas:
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. menu menu menu menu menu menu menu menu menu menu .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. icons icons icons icons icons icons icons icons icons icons .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .'
    '. imprint imprint imprint imprint imprint imprint imprint imprint imprint imprint .'
    '. . . . . . . . . . . .'
    '. . . . . . . . . . . .';
`;

export const SidebarMenuWrapper = styled.div`
  grid-area: menu;
`;

export const SidebarMenu = styled.ul`
  display: grid;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
`;

export const SidebarRoute = styled(LinkR)`
  display: flex;
  justify-content: center;
  font-size: 4rem;
  letter-spacing: 1.6px;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.fontDark};
  font-variation-settings: 'wght' 400;

  &::before {
    content: ' ';
    margin-right: 1rem;
    transition: width 0.5s;
  }

  &:hover::before {
    content: url('/smiley.svg');
    width: 3rem;
    margin-right: 1rem;
    transform: rotate(360deg);
    transition: all 3s;
  }
  &:active::before {
    transition: all 0.5s ease;
    //color: ${({ theme }) => theme.colors.hoverLight};
  }

  @media screen and (max-width: 820px) {
    font-size: 4rem;
  }
`;

export const SidebarSocialIconsWrapper = styled.div`
  grid-area: icons;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
`;

export const SocialIconsHeadline = styled.h2`
  display: flex;
  //text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.size.large};
  font-variation-settings: 'wght' 500;
  letter-spacing: 0.16rem;
`;

export const SidebarSocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const SidebarSocialIconLink = styled.a`
  color: ${({ theme }) => theme.colors.fontDark};
  font-size: 3.5rem;
  text-align: center;
  transition: ${({ theme }) => theme.transition.link};

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
  }
`;

export const CopyrightWrapper = styled.div`
  grid-area: imprint;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarRights = styled.small`
  color: ${({ theme }) => theme.colors.fontDark};
  text-align: center;
`;

export const FooterLinkImprint = styled(LinkR)`
  color: ${({ theme }) => theme.colors.fontDark};
  text-decoration: none;
  font-size: ${({ theme }) => theme.size.small};
  transition: ${({ theme }) => theme.transition.link};

  &:hover {
    //color: ${({ theme }) => theme.colors.hoverLight};
  }
`;
