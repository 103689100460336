import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import AnimatedCursor from './cursor/AnimatedCursor';

ReactDOM.render(
  <>
    <Router>
      <AnimatedCursor />
      <App />
    </Router>
  </>,

  document.getElementById('root'),
);
