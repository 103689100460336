import React from 'react';
import { FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { HiOutlineChevronUp } from 'react-icons/hi';
import CircleUp from '../../assets/upcircle.svg';
import { animateScroll as scroll } from 'react-scroll';
import { motion } from 'framer-motion';
import {
  FooterContainer,
  FooterWrap,
  FooterTextWrap,
  DesktopWrap,
  FooterLinkItems,
  FooterLinkMobile,
  FooterLinkDesktop,
  DesktopFooterLinkItems,
  SocialIconsWrap,
  SocialIconsHeadline,
  SocialIcons,
  SocialIconLink,
  TopScrollWrap,
  TopScrollIcon,
  DesktopIconWrap,
  WebsiteRights,
  FooterLinkImprint,
} from './FooterElements';
import MagneticElement from '../magnetic/MagneticElement';

const Footer = () => {
  const toggleTop = () => {
    scroll.scrollToTop();
  };

  const reveal = {
    hidden: { y: 50, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.25,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
    exit: {
      opacity: 0,
      scale: 0.98,
      transition: {
        duration: 1,
        ease: [0.16, 1, 0.3, 1],
      },
    },
  };

  return (
    <FooterContainer as={motion.div} variants={reveal} initial="hidden" animate="show" exit="exit">
      <FooterWrap>
        <FooterTextWrap>
          <p>
            Let’s create
            <br />
            something
            <br />
            <span>amazing</span>
            <br />
            together!
          </p>
          <a href="mailto:create@leodoerfler.com">create@leodoerfler.com</a>
        </FooterTextWrap>
        <DesktopWrap>
          <SocialIcons>
            <SocialIconLink
              href="//www.instagram.com/leo.doerfler/"
              target="_blank"
              aria-label="instagram"
              alt="instagram"
            >
              <MagneticElement aria-label="instagram">
                <FaInstagram size={24} />
              </MagneticElement>
            </SocialIconLink>
            <SocialIconLink
              href="//www.youtube.com/leodoerfler/"
              target="_blank"
              aria-label="youtube"
              alt="youtube"
            >
              <MagneticElement aria-label="youtube">
                <FaYoutube size={24} />
              </MagneticElement>
            </SocialIconLink>
            <SocialIconLink
              href="//www.linkedin.com/in/leodoerfler"
              target="_blank"
              aria-label="linkedin"
              alt="linkedin"
            >
              <MagneticElement aria-label="linkedin">
                <FaLinkedin size={24} />
              </MagneticElement>
            </SocialIconLink>
          </SocialIcons>
        </DesktopWrap>
        <SocialIconsWrap>
          <SocialIcons>
            <SocialIconLink
              href="//www.instagram.com/leo.doerfler/"
              target="_blank"
              aria-label="instagram"
              alt="instagram"
            >
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink
              href="//www.youtube.com/leodoerfler/"
              target="_blank"
              aria-label="youtube"
              alt="youtube"
            >
              <FaYoutube />
            </SocialIconLink>
            <SocialIconLink
              href="//www.linkedin.com/in/leodoerfler"
              target="_blank"
              aria-label="linkedin"
              alt="linkedin"
            >
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialIconsWrap>
        <WebsiteRights>
          <p>copyright © {new Date().getFullYear()} /</p>
          <MagneticElement
            style={{
              width: '60px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FooterLinkImprint to="/imprint">imprint</FooterLinkImprint>
          </MagneticElement>
        </WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
