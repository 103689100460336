import React, { useRef, useState, useEffect, createRef } from 'react';
import { gsap } from 'gsap';
import styled from 'styled-components';

const MagneticContainer = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.fontLight};
  border: none;
  padding: 0;
  position: relative;
  touch-action: none;

  span {
    display: flex;
  }

  &::hover {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const MagneticElement = ({
  children,
  speed = 1,
  tolerance = 0.8,
  scale = 1.2,
  debug = false,
  borderRadius = 0,
  ...props
}) => {
  const $root = useRef();
  const $item = useRef();
  const $hover = useRef();
  const rootBound = useRef();
  const itemBound = useRef();
  const diffBound = useRef({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    gsap.killTweensOf($item.current);
    gsap.set($hover.current, {
      scale: scale,
      borderRadius,
      background: debug ? 'rgba(0, 125, 255, .4)' : 'transparent',
    });

    rootBound.current = $root.current.getBoundingClientRect();
    itemBound.current = $item.current.getBoundingClientRect();
    diffBound.current.x = (rootBound.current.width * scale - rootBound.current.width) / 2;
    diffBound.current.y = (rootBound.current.height * scale - rootBound.current.height) / 2;
  };

  const handleMouseLeave = () => {
    gsap.killTweensOf($item.current);
    gsap.to($item.current, {
      x: 0,
      y: 0,
      ease: 'elastic.out(1.1, .4)',
      duration: 1.2,
    });
    gsap.set($hover.current, {
      scale: 1,
    });
  };

  const handleMouseMove = (e) => {
    const x = e.clientX || e.touches[0].clientX;
    const y = e.clientY || e.touches[0].clientY;

    const maxX = ((rootBound.current.width - itemBound.current.width) / 2) * tolerance;
    const maxY = ((rootBound.current.height - itemBound.current.height) / 2) * tolerance;

    const newX = gsap.utils.mapRange(
      0,
      rootBound.current.width * scale,
      -maxX,
      maxX,
      x - rootBound.current.x + diffBound.current.x,
    );

    const newY = gsap.utils.mapRange(
      0,
      rootBound.current.height * scale,
      -maxY,
      maxY,
      y - rootBound.current.y + diffBound.current.y,
    );

    gsap.killTweensOf($item.current);
    gsap.to($item.current, {
      x: newX,
      y: newY,
      ease: 'power3.out',
      duration: speed,
    });
  };

  return (
    <MagneticContainer
      ref={$root}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
      onTouchStart={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchEnd={handleMouseLeave}
      {...props}
    >
      <span ref={$item}>{children}</span>
      <span ref={$hover} />
    </MagneticContainer>
  );
};

export default MagneticElement;
